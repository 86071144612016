<script setup>
import { useRoute, useRouter } from 'vue-router'
import { watch } from 'vue'
import { useNavigationStore } from '~/store/navigation.js'

defineProps({
  navigation: {
    type: Array,
    required: true,
  },
})

const route = useRoute()
const router = useRouter()

const navigationStore = useNavigationStore()

const caseRoute = ref(null)

const isExpanded = computed(() => navigationStore.isExpanded)

const handleClick = (href) => {
  const pathOnly = href.split('?')[0]
  if (route.path === pathOnly) {
    window.location.href = href
  } else {
    router.push(href)
  }
}
</script>

<template>
  <nav class="flex flex-col gap-1">
    <div v-for="(item, index) in navigation" :key="index">
      <el-menu
        :default-active="route.path"
        unique-opened
        :collapse-transition="false"
        :collapse="!isExpanded"
        :background-color="isExpanded ? '#fff' : '#fff'"
        :text-color="isExpanded ? '#303133' : '#606266'"
        router
        :default-openeds="[route.path]"
      >
        <el-menu-item
          v-if="item.visible && !item.children"
          :index="item.href"
          :class="[
            'flex cursor-pointer items-center gap-3 rounded text-left text-sm transition-all duration-200 ease-in-out',
            {
              'hover:bg-primary hover:text-white': !item.alternative ?? true,
              'px-0': !isExpanded,
              'bg-primary text-white shadow': route.fullPath === item.href || caseRoute === item.href,

              'bg-yellow-400 text-black': (item.alternative ?? false) && route.fullPath !== item.href,
            },
          ]"
        >
          <div
            v-if="item.visible"
            class="group flex w-full items-center gap-3 rounded px-2 py-3 text-left text-sm"
            @click="handleClick(item.href)"
          >
            <component :is="item.icon" v-if="item.icon" :class="isExpanded ? '' : 'mx-auto'" class="h-5 w-5 flex-shrink-0" />
            <img
              v-if="item.img"
              :src="item.img"
              width="20"
              height="20"
              :class="[
                'h-5 w-5 flex-shrink-0 group-hover:invert',
                { invert: route.fullPath === item.href || caseRoute === item.href },
                { 'mx-auto': isExpanded },
              ]"
            />

            <div v-if="isExpanded" class="line-clamp-2 flex min-w-[170px] flex-1 flex-col gap-px">
              <p class="text-sm" v-html="item.title" />
              <p v-if="item.subtitle" class="text-xs">{{ item.subtitle }}</p>
            </div>
          </div>
        </el-menu-item>
        <el-sub-menu
          v-else-if="item.visible && item.children"
          :index="item.title"
          :class="[
            {
              'active bg-primary-submenu text-white': route.fullPath === item.href || caseRoute === item.href,
              'bg-primary-submenu-hover text-white': !item.alternative ?? true,
              'w-fit px-0': !isExpanded,
            },
          ]"
        >
          <template #title>
            <div
              v-if="item.visible"
              :class="[
                'flex cursor-pointer items-center gap-3 rounded px-2 py-1 text-left text-sm transition-all duration-100 ease-in-out',
                {
                  'hover:text-white': !item.alternative ?? true,
                },
              ]"
            >
              <component :is="item.icon" :class="isExpanded ? '' : 'mx-auto'" class="h-5 w-5 flex-shrink-0" />
              <div v-if="isExpanded" class="line-clamp-2 flex min-w-[170px] flex-1 flex-col gap-px">
                <p class="text-sm" v-html="item.title" />
                <p v-if="item.subtitle" class="text-xs">{{ item.subtitle }}</p>
              </div>
            </div>
          </template>
          <div v-for="(child, childIndex) in item.children" :key="childIndex">
            <el-menu-item :index="child.href" :class="route.path === child.href ? 'mt-1 rounded bg-primary text-white' : ''">
              <div
                :class="[
                  'flex w-full cursor-pointer items-center gap-3 rounded px-2 py-3 text-left text-sm',
                  {
                    'hover:bg-primary hover:text-white': !child.alternative ?? false,
                    'px-0': !isExpanded,
                    'bg-primary text-white': route.fullPath === child.href || caseRoute === child.href,

                    'bg-yellow-400 text-black': (child.alternative ?? false) && route.fullPath !== child.href,
                  },
                ]"
                @click="handleClick(child.href)"
              >
                <component :is="child.icon" :class="isExpanded ? '' : 'mx-auto'" class="h-5 w-5 flex-shrink-0" />
                <div v-if="isExpanded" class="line-clamp-2 flex flex-1 flex-col gap-px">
                  <p class="mt-1 text-sm" v-html="child.title" />
                  <p v-if="item.subtitle" class="text-xs">{{ child.subtitle }}</p>
                </div>
              </div>
            </el-menu-item>
          </div>
        </el-sub-menu>
      </el-menu>
    </div>
  </nav>
</template>

<style lang="scss">
.el-menu {
  border: none;
  --el-menu-hover-bg-color: #fff !important;
  --bg-color: #fff !important;
}
.el-sub-menu__title {
  .el-sub-menu__icon-arrow {
    @apply mr-4;
  }
}
.bg-primary-submenu {
  .el-sub-menu__title {
    @apply bg-primary;
    @apply rounded;
  }
}
.bg-primary-submenu-hover {
  &.active {
    .el-sub-menu__title {
      @apply text-white;
    }
  }
  .el-sub-menu__title {
    &:hover {
      @apply bg-primary;
      @apply text-white;
      @apply rounded;
    }
  }
}
.menu-icon-svg:hover {
  filter: invert(1);
}
</style>
